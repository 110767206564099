import { scopes } from './evniroment-constants';

export const environment = {
  production: false,
  apiBaseUrl: 'https://yardqualitycheck-api.test.doka.com',
  translationsApiBaseUrl: 'https://yard-api.test.doka.com',
  oAuth: {
    issuer: 'https://login.test.doka.com',
    redirectUri: `${window.location.origin}/login-callback`,
    loginUrl: window.location.origin,
    clientId: 'b43ac3a7-11a0-4a0b-8f43-acfc9bef875a',
    scope: Object.values(scopes).join(' '),
    responseType: 'code',
  },
};
